<template>
    <div class="cron-generator">
        <div class="box">
            <i-input v-model="localExpression" placeholder="请输入 cron 表达式" class="inp" @input="updateParent" />
            <i-button type="primary" @click="showDialog">生成 cron</i-button>
        </div>
        <Modal title="生成 cron" v-model="showCron" width="600" :footer-hide="true">
            <vcrontab @hide="showCron = false" @fill="crontabFill" :expression="localExpression"></vcrontab>
        </Modal>
    </div>
</template>


<script>
import vcrontab from 'vcrontab'

export default {
    name: 'CronGenerator',
    components: { vcrontab },
    props: {
        expression: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            localExpression: this.expression, // 本地变量用于输入和显示的表达式
            showCron: false
        };
    },
    methods: {
        crontabFill(value) {
            // 确定后回传的值
            this.localExpression = value;
            this.$emit('update:expression', value); // 通过事件传递给父组件
        },
        showDialog() {
            this.localExpression = this.expression; // 传入的 cron 表达式，可反解析到 UI 上
            this.showCron = true;
        },
        updateParent() {
            this.$emit('update:expression', this.localExpression);
        }
    },
    watch: {
        expression(newVal) {
            this.localExpression = newVal;
        }
    }
};
</script>

<style scoped>
.cron-generator .box {
    display: flex;
    align-items: center;
    gap: 10px;
}
</style>
