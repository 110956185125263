<template>
  <div class="container">
    <div class="card">
      <div class="header">
        <div class="query-c">
          <span>定时任务名称：</span>
          <Input search placeholder="请输入任务名称" style="width: 200px" v-model="searchKey" @on-enter="handleSearch"
            clearable @on-clear="clearSearch" />
        </div>
        <Button type="primary" @click="showAddTaskModal">添加任务</Button>
      </div>
      <Table max-height="440" border highlight-row stripe :columns="columns1" :data="data1"></Table>
      <br />
      <Page :total="total" show-sizer prev-text="上一页" @on-change="change" @on-page-size-change="pageSizeCall"
        next-text="下一页" />
    </div>

    <Modal v-model="isAddTaskModalVisible" title="添加任务" @on-ok="addTask" @on-cancel="closeAddTaskModal" width="600">
      <div class="form-item">
        <span>任务名称：</span>
        <Input v-model="newTaskName" placeholder="请输入任务名称" />
      </div>
      <div class="form-item">
        <span>cron表达式：</span>
        <Cron :expression.sync="newCronExpression"></Cron>
      </div>
      <div class="form-item">
        <span>执行脚本：</span>
        <codemirror v-model="newScript" class="codemirror-container" :options="cmOptions"></codemirror>
      </div>
    </Modal>

    <Modal v-model="isEditTaskModalVisible" title="修改任务" @on-ok="editTask" @on-cancel="closeEditTaskModal" width="500">
      <div class="form-item">
        <span>任务名称：</span>
        <Input v-model="editTaskName" placeholder="请输入任务名称" />
      </div>
      <div class="form-item">
        <span>cron表达式：</span>
        <Cron :expression.sync="editCronExpression"></Cron>
      </div>
    </Modal>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/json-lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/markdown-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/xml-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/anyword-hint";
import "codemirror/addon/search/match-highlighter";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";
import "codemirror/mode/css/css.js";
import "codemirror/mode/vue/vue.js";
import { listPage, saveTask, removeTask, pauseTask, resumeTask, modifyTask, executeTaskNow } from "@/api/DynamicTask.js";
import { codemirror } from "vue-codemirror";
import Cron from "@/components/task/Cron.vue";

export default {
  name: "DynamicTask",
  components: {
    codemirror,
    Cron,
  },
  data() {
    return {
      searchKey: "",
      pageSize: 10,
      firstResult: 0,
      total: 0,
      isAddTaskModalVisible: false,
      isEditTaskModalVisible: false,
      newTaskName: "",
      newCronExpression: "",
      newScript: "",
      editJobId: "",
      editTaskName: "",
      editCronExpression: "",
      codemirrorStyle: {
        fontSize: "18px",
        lineHeight: "150%",
        height: "280px",
        border: "1px solid #EBEEF5",
      },
      cmOptions: {
        mode: "groovy",
        lineNumbers: true,
        line: true,
        lint: true,
        lineWrapping: true,
        autofocus: true,
        autoCloseBrackets: true,
        foldGutter: true, // 块槽
        hintOptions: { completeSingle: true },
        matchTags: { bothTags: true },
        matchBrackets: true,
        showCursorWhenSelecting: true,
        styleSelectedText: true,
        styleActiveLine: true,
        autoRefresh: true,
        highlightSelectionMatches: {
          minChars: 2,
          trim: true,
          style: "matchhighlight",
          showToken: false,
        },
      },
      columns1: [
        { title: "任务ID", key: "jobId" },
        { title: "任务名称", key: "remark" },
        { title: "cron表达式", key: "cronExpression" },
        { title: "状态", key: "status" },
        { title: "上一次执行时间", key: "lastRunTime" },
        { title: "下一次执行时间", key: "nextRunTime" },
        {
          title: "操作",
          key: "action",
          render: (h, params) => {
            return h(
              "div",
              { style: { display: "flex", flexWrap: "wrap", gap: "8px" } },
              [
                h(
                  "Poptip",
                  {
                    props: {
                      confirm: true,
                      title: "确认删除该任务？",
                      transfer: true,
                      placement: "top",
                    },
                    on: {
                      "on-ok": () => this.handleDelete(params.row.jobId),
                    },
                  },
                  [
                    h(
                      "a",
                      { class: "action-link" },
                      [h("i", { class: "iview-icon iview-icon-delete" }), " 删除"]
                    ),
                  ]
                ),
                h(
                  "a",
                  {
                    on: { click: () => this.showEditTaskModal(params.row) },
                    class: "action-link",
                  },
                  [h("i", { class: "iview-icon iview-icon-edit" }), " 修改"]
                ),
                h(
                  "Poptip",
                  {
                    props: {
                      confirm: true,
                      title: "确认立即执行该任务？",
                      transfer: true,
                      placement: "top",
                    },
                    on: {
                      "on-ok": () => this.handleExecute(params.row.jobId),
                    },
                  },
                  [
                    h(
                      "a",
                      { class: "action-link" },
                      [h("i", { class: "iview-icon iview-icon-play-circle" }), " 立即执行"]
                    ),
                  ]
                ),
                ...(params.row.status === "就绪"
                  ? [
                    h(
                      "Poptip",
                      {
                        props: {
                          confirm: true,
                          title: "确认暂停该任务？",
                          transfer: true,
                          placement: "top",
                        },
                        on: {
                          "on-ok": () => this.handlePause(params.row.jobId),
                        },
                      },
                      [
                        h(
                          "a",
                          { class: "action-link" },
                          [h("i", { class: "iview-icon iview-icon-pause" }), " 暂停"]
                        ),
                      ]
                    ),
                  ]
                  : []),
                ...(params.row.status === "暂停"
                  ? [
                    h(
                      "Poptip",
                      {
                        props: {
                          confirm: true,
                          title: "确认恢复该任务？",
                          transfer: true,
                          placement: "top",
                        },
                        on: {
                          "on-ok": () => this.handleResume(params.row.jobId),
                        },
                      },
                      [
                        h(
                          "a",
                          { class: "action-link" },
                          [h("i", { class: "iview-icon iview-icon-play-circle" }), " 恢复"]
                        ),
                      ]
                    ),
                  ]
                  : []),
              ]
            );
          },
        }
        ,
      ],
      data1: [],
    };
  },
  methods: {
    showAddTaskModal() {
      this.isAddTaskModalVisible = true;
      this.newTaskName = "";
      this.newCronExpression = "";
      this.newScript = "";
    },
    showEditTaskModal(task) {
      this.isEditTaskModalVisible = true;
      this.editTaskName = task.remark;
      this.editCronExpression = task.cronExpression;
      this.editJobId = task.jobId;
    },
    closeAddTaskModal() {
      this.isAddTaskModalVisible = false;
    },
    closeEditTaskModal() {
      this.isEditTaskModalVisible = false;
    },
    addTask() {
      saveTask({
        remark: this.newTaskName,
        cronExpression: this.newCronExpression,
        script: this.newScript,
      }).then(() => {
        this.closeAddTaskModal();
        this.getPage();
      });
    },
    editTask() {
      modifyTask({
        jobId: this.editJobId,
        remark: this.editTaskName,
        cronExpression: this.editCronExpression,
      }).then(res => {
        if (res.code === 0) {
          this.$Message.success("修改成功");
          this.closeEditTaskModal();
          this.getPage();
        } else {
          this.$Message.error("修改失败");
        }
      });
    },
    handleSearch() {
      this.getPage();
    },
    clearSearch() {
      this.searchKey = "";
      this.getPage();
    },
    pageSizeCall(val) {
      this.pageSize = val;
      this.getPage();
    },
    change(val) {
      this.firstResult = this.calculateStartData(val, this.pageSize);
      this.getPage();
    },
    calculateStartData(currentPage, pageSize) {
      return (currentPage - 1) * pageSize;
    },
    getPage() {
      listPage({
        search: this.searchKey,
        pageSize: this.pageSize,
        firstResult: this.firstResult,
      }).then((res) => {
        this.data1 = res.retVal.datas;
        this.total = res.retVal.totalRows;
      });
    },
    handleDelete(jobId) {
      removeTask({ jobId: jobId }).then(res => {
        if (res.code === 0) {
          this.$Message.success("删除成功");
          this.getPage();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    handleExecute(jobId) {
      executeTaskNow({ jobId: jobId }).then(res => {
        if (res.code === 0) {
          this.$Message.success("执行成功");
          this.getPage();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    handlePause(jobId) {
      pauseTask({ jobId: jobId }).then(res => {
        if (res.code === 0) {
          this.$Message.success("暂停成功");
          this.getPage();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    handleResume(jobId) {
      resumeTask({ jobId: jobId }).then(res => {
        if (res.code === 0) {
          this.$Message.success("恢复成功");
          this.getPage();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    this.getPage();
  },
};
</script>

<style scoped>
.container {
  background: #f5f7fa;
  padding: 20px;
}

.card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.query-c {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.codemirror-container {
  width: 100%;
  min-width: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

a {
  color: #3b3b3b;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

a:hover {
  background-color: #e6f7ff;
}

.iview-icon {
  margin-right: 4px;
}
</style>
