import request from '@/utils/request'

export function listPage(data) {
    return request.get('/diary/dynamicTask/findPage', { params: data })
}

export function saveTask(data) {
    return request.post('/diary/dynamicTask/addTask', data)
}

export function modifyTask(data) {
    return request.post('/diary/dynamicTask/modifyTask', data)
}

export function removeTask(data) {
    return request.get('/diary/dynamicTask/removeTask', { params: data })
}

export function pauseTask(data) {
    return request.get('/diary/dynamicTask/pauseTask', { params: data })
}

export function resumeTask(data) {
    return request.get('/diary/dynamicTask/resumeTask', { params: data })
}

export function executeTaskNow(data) {
    return request.get('/diary/dynamicTask/executeTaskNow', { params: data })
}
